import React from 'react';
import classNames from 'classnames';
import { SiSlack } from 'react-icons/si';
import { EnvelopeIcon } from '@heroicons/react/20/solid';

export default ({ owner }) => (
	<section className="p-6 overflow-hidden bg-white rounded-lg shadow">
		<h2 className="text-lg font-medium text-gray-900">Ownership</h2>
		<div className="flex mt-4 rounded-md shadow-sm">
			<div
				className={classNames(
					'flex-shrink-0 flex items-center justify-center w-5 text-white text-sm font-medium rounded-l-md bg-gray-300',
					owner.team.toLowerCase().includes('red') ? 'bg-red-600' : '',
					owner.team.toLowerCase().includes('yellow') ? 'bg-yellow-400' : '',
					owner.team.toLowerCase().includes('blue') ? 'bg-blue-600' : '',
					owner.team.toLowerCase().includes('green') ? 'bg-green-600' : '',
					owner.team.toLowerCase().includes('pink') ? 'bg-pink-400' : '',
					owner.team.toLowerCase().includes('purple') ? 'bg-purple-600' : ''
				)}
			/>
			<div className="flex items-center justify-between flex-1 bg-white border-t border-b border-r border-gray-200 rounded-r-md">
				<div className="flex-1 px-4 py-2">
					<div className="text-gray-900 hover:text-gray-600">Team: {owner.team}</div>
				</div>
			</div>
		</div>
		{owner.slack?.channelId && (
			<div className="flex items-center justify-between mt-4">
				<SiSlack
					className="flex-shrink-0 w-5 h-5 text-gray-400"
					title={`Slack Channel: #${owner.slack.channelName}`}
				/>
				<span className="flex-1 w-0 ml-2">
					<a
						href={`slack://channel?team=T06UW32H0&id=${owner.slack.channelId}`}
						target="_blank"
						rel="noreferrer"
					>
						Slack: #{owner.slack.channelName}
					</a>
				</span>
			</div>
		)}
		{owner.email?.length > 0 &&
			owner.email.map((email) => (
				<div className="flex items-center justify-between mt-4" key={email}>
					<div className="flex items-center flex-1 w-0">
						<EnvelopeIcon className="flex-shrink-0 w-5 h-5 text-gray-400" />
						<span className="flex-1 w-0 ml-2">
							<a href={`mailto:${email}`}>Email: {email}</a>
						</span>
					</div>
				</div>
			))}
	</section>
);
