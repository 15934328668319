import { React, useEffect, useState } from 'react';
import useApi from '../../hooks/useApi';
import Breadcrumbs from './Breadcrumbs';
import Header from './Header';
import Actions from './Actions';
import Links from './Links';
import Repository from './Repository';
import Ownership from './Ownership';
import Contacts from './Contacts';
import Notes from './Notes';

const Container = ({ children }) => (
	<div className="max-w-3xl mx-auto lg:max-w-7xl">{children}</div>
);
const Grid = ({ children }) => (
	<div className="grid items-start grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8">{children}</div>
);
const LeftCol = ({ children }) => (
	<div className="grid grid-cols-1 gap-4 lg:col-span-2">{children}</div>
);
const RightCol = ({ children }) => <div className="grid grid-cols-1 gap-4">{children}</div>;

const ServiceItem = () => {
	const { get } = useApi();
	const [apiData, setApiData] = useState(null);

	useEffect(() => {
		if (!apiData) {
			const [slug] = window.location.href.split('/').slice(-1);
			get(`/v1/catalog/service/${slug}`).then((data) => setApiData(data));
		}
	}, [get, apiData]);

	return (
		apiData && (
			<Container>
				<Breadcrumbs serviceName={apiData.service.name} slug={apiData.slug} />
				<Grid>
					<LeftCol>
						<Header service={apiData.service} />
						<Actions
							deployment={apiData.service.deployment}
							monitoring={apiData.service.monitoring}
							hosting={apiData.service.hosting}
							issues={apiData.service.issues}
						/>
						<Links
							links={apiData.service.links}
							monitoring={apiData.service.monitoring}
						/>
					</LeftCol>
					<RightCol>
						<Repository repository={apiData.service.repository} />
						<Ownership owner={apiData.service.owner} />
						<Contacts contacts={apiData.service.contacts} />
						<Notes notes={apiData.service.notes} />
					</RightCol>
				</Grid>
			</Container>
		)
	);
};

export default ServiceItem;
