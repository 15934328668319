import React from 'react';
import HealthTableRow from '../HealthTableRow';
import './index.css';

const ThService = () => <th className="w-1/6">Service</th>;
const ThRegion = ({ children }) => <th className="w-1/12">{children}</th>;
const HealthTable = ({ rows }) => (
	<table className="text-center bg-white health-table">
		<thead>
			<tr>
				<ThService />
				<ThRegion>
					UK
					<br />
					South
				</ThRegion>
				<ThRegion>
					UK
					<br />
					West
				</ThRegion>
				<ThRegion>
					North
					<br />
					Europe
				</ThRegion>
				<ThRegion>
					West
					<br />
					Europe
				</ThRegion>
				<ThRegion>
					Canada
					<br />
					Central
				</ThRegion>
				<ThRegion>
					Canada
					<br />
					East
				</ThRegion>
				<ThRegion>
					Australia
					<br />
					East
				</ThRegion>
				<ThRegion>
					Australia
					<br />
					SouthEast
				</ThRegion>
			</tr>
		</thead>
		<tbody>{rows}</tbody>
	</table>
);

const HealthTableWithData = ({ data }) => {
	const groupedByService = data.reduce((r, a) => {
		const parts = a.name.split('|');
		// eslint-disable-next-line no-param-reassign
		r[parts[0]] = r[parts[0]] || [];
		r[parts[0]].push(a);
		return r;
	}, Object.create(null));

	const rows = [];
	Object.entries(groupedByService).forEach(([key, value]) => {
		rows.push(<HealthTableRow key={key} serviceName={key} serviceHealth={value} />);
	});

	return <HealthTable rows={rows} />;
};

export default HealthTableWithData;
