import React from 'react';
import ReactDOM from 'react-dom/client';
import { PublicClientApplication } from '@azure/msal-browser';
import { getMsalConfig } from './utils/msalConfig';
import getEnv from './utils/getEnv';
import App from './App';
import './index.css';

const environment = getEnv();
const msalConfig = getMsalConfig(environment);

// MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<App msalInstance={msalInstance} />
	</React.StrictMode>
);
