import getEnv from '../getEnv';

const environment = getEnv();

const envConfig = (env = 'sand') => ({
	apiUrl: {
		prod: 'https://api.brighthr.com',
		sand: 'http://localhost:7116',
	}[env],
	apiScopes: {
		prod: ['api://86313821-3943-4e1d-93da-e134a9ad1e60/catalog.readwrite'],
		sand: ['api://cee94f49-d1eb-48aa-9a78-e5e2ff8e9ff6/catalog.readwrite'],
	}[env],
});

const getConfig = () => ({
	msGraphApiUrl: 'https://graph.microsoft.com/v1.0/me',
	msGraphApiScopes: ['User.Read'], // MSAL adds OIDC scopes by deafult (openid, profile, email), no need to request here
	environment,
	...envConfig(environment),
});

export default getConfig;
