import { React, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import { SiSlack, SiAzuredevops, SiAzurepipelines, SiGithub } from 'react-icons/si';
import useApi from '../../hooks/useApi';

const Th = ({ children }) => (
	<th
		scope="col"
		className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
	>
		{children}
	</th>
);

const Td = ({ children, className, title }) => (
	<td title={title} className={classnames('px-6 py-2 whitespace-nowrap text-sm', className)}>
		{children}
	</td>
);

const ServiceList = () => {
	const { get } = useApi();
	const [apiData, setApiData] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		if (!apiData) {
			get('/v1/catalog/service').then((data) => setApiData(data));
		}
	}, [get, apiData]);

	return (
		<>
			<div className="flex flex-col">
				<div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
						<div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
							<table className="min-w-full divide-y divide-gray-200">
								<thead className="bg-gray-200">
									<tr>
										<Th>Name</Th>
										<Th>Type</Th>
										<Th>Owner</Th>
										<Th>Quick Links</Th>
									</tr>
								</thead>
								<tbody className="bg-white divide-y divide-gray-200">
									{apiData &&
										apiData.map((item) => (
											<tr
												key={item.service.name}
												className="cursor-pointer"
												onClick={() => navigate(`/services/${item.slug}`)}
											>
												<Td title={item.service.description}>
													{item.service.name}
												</Td>
												<Td>{item.service.component}</Td>
												<Td>{item.service.owner.team}</Td>
												<Td>
													<div className="flex items-center">
														<a
															className="mr-3 text-lg text-gray-600"
															href={item.service.repository.url}
															target="_blank"
															rel="noreferrer"
														>
															<SiGithub
																title={`GitHub: ${item.service.repository.owner}/${item.service.repository.name}`}
															/>
														</a>
														{item.service.owner.slack.channelId && (
															<a
																className="mr-3 text-lg text-gray-600"
																href={`slack://channel?team=T06UW32H0&id=${item.service.owner.slack.channelId}`}
																target="_blank"
																rel="noreferrer"
															>
																<SiSlack
																	title={`Slack Channel: #${item.service.owner.slack.channelName}`}
																/>
															</a>
														)}
														{item.service.deployment?.azureDevops
															?.projectName && (
															<a
																className="mr-3 text-lg text-gray-600"
																href={`https://dev.azure.com/Bright-HR/${item.service.deployment.azureDevops.projectName}/_build`}
																target="_blank"
																rel="noreferrer"
															>
																<SiAzurepipelines title="DevOps Pipelines" />
															</a>
														)}
														{item.service.monitoring?.appInsights
															?.url && (
															<a
																className="mr-3 text-lg text-gray-600"
																href={
																	item.service.monitoring
																		.appInsights?.url
																}
																target="_blank"
																rel="noreferrer"
															>
																<SiAzuredevops title="Azure App Insights" />
															</a>
														)}
													</div>
												</Td>
											</tr>
										))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ServiceList;
