import React from 'react';

export default ({ notes }) =>
	notes && (
		<section>
			<div className="overflow-hidden bg-white rounded-lg shadow">
				<div className="p-6">
					<h2 className="text-lg font-medium text-gray-900" id="recent-hires-title">
						Notes
					</h2>
					<div className="mt-4">
						<p className="text-sm text-gray-500">{notes}</p>
					</div>
				</div>
			</div>
		</section>
	);
