import React from 'react';

const XCircleOutline = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth={1.5}
		stroke="currentColor"
		className="w-6 h-6"
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
		/>
	</svg>
);
const XCircleFill = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="currentColor"
		className="w-6 h-6"
	>
		<path
			fillRule="evenodd"
			d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z"
			clipRule="evenodd"
		/>
	</svg>
);
const ExclamationCircleOutline = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth="1.5"
		stroke="currentColor"
		className="w-6 h-6"
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
		/>
	</svg>
);
const ExclamationCircleFill = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="currentColor"
		className="w-6 h-6"
	>
		<path
			fillRule="evenodd"
			d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
			clipRule="evenodd"
		/>
	</svg>
);
const CheckCircleOutline = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		fill="none"
		viewBox="0 0 24 24"
		strokeWidth={1.5}
		stroke="currentColor"
		className="w-6 h-6"
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
		/>
	</svg>
);
const CheckCircleFill = () => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 24 24"
		fill="currentColor"
		className="w-6 h-6"
	>
		<path
			fillRule="evenodd"
			d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
			clipRule="evenodd"
		/>
	</svg>
);

export const HealthyIcon = ({ primaryRegion, title, className }) =>
	primaryRegion ? (
		<CheckCircleFill
			className={className}
			style={{ color: '#008000' }}
			title={title || 'Healthy Primary'}
		/>
	) : (
		<CheckCircleOutline
			className={className}
			style={{ color: '#008000' }}
			title={title || 'Healthy Secondary'}
		/>
	);

export const DegradedIcon = ({ primaryRegion, title, className }) =>
	primaryRegion ? (
		<ExclamationCircleFill
			className={className}
			style={{ color: '#FFA501' }}
			title={title || 'Degraded Primary'}
		/>
	) : (
		<ExclamationCircleOutline
			className={className}
			style={{ color: '#FFA501' }}
			title={title || 'Degraded Secondary'}
		/>
	);

export const UnhealthyIcon = ({ primaryRegion, title, className }) =>
	primaryRegion ? (
		<XCircleFill
			className={className}
			style={{ color: '#FF0200' }}
			title={title || 'Unhealthy Primary'}
		/>
	) : (
		<XCircleOutline
			className={className}
			style={{ color: '#FF0200' }}
			title={title || 'Unhealthy Secondary'}
		/>
	);

export const IconForStatus = ({ status, primary, title, className }) =>
	({
		Unhealthy: <UnhealthyIcon primaryRegion={primary} title={title} className={className} />,
		Degraded: <DegradedIcon primaryRegion={primary} title={title} className={className} />,
	}[status] || <HealthyIcon primaryRegion={primary} title={title} className={className} />);
