import { React } from 'react';
import { Bars3BottomLeftIcon } from '@heroicons/react/24/outline';

const Header = ({ setMobileMenuOpen }) => (
	<header className="w-full md:hidden">
		<div className="relative z-10 flex flex-shrink-0 h-16 bg-white border-b border-gray-200 shadow-sm">
			<button
				type="button"
				className="px-4 text-gray-500 border-r border-gray-200 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
				onClick={() => setMobileMenuOpen(true)}
			>
				<span className="sr-only">Open sidebar</span>
				<Bars3BottomLeftIcon className="w-6 h-6" aria-hidden="true" />
			</button>
			<div className="flex justify-between flex-1 px-4 sm:px-6">
				<div className="flex items-center flex-1 text-lg">BrightHR Software Catalog</div>
			</div>
		</div>
	</header>
);

export default Header;
