import { React, useState } from 'react';
import {
	CogIcon,
	RectangleStackIcon,
	BellAlertIcon,
	CodeBracketIcon,
	ListBulletIcon,
	ChartBarIcon,
	HeartIcon,
	HomeIcon,
} from '@heroicons/react/24/outline';
import NavDesktop from '../../NavDesktop';
import NavMobile from '../../NavMobile';
import Header from '../../Header';

const navItems = [
	{ name: 'Home', href: '/', icon: HomeIcon, current: false },
	{ name: 'Services', href: '/services', icon: RectangleStackIcon, current: false },
	{ name: 'Health', href: '/health', icon: HeartIcon, current: false },
	// { name: 'Teams', href: '#', icon: UserGroupIcon, current: false },
	{ name: 'Settings', href: '/settings', icon: CogIcon, current: false },
	{
		name: 'Alerts',
		href: 'https://portal.azure.com/#blade/Microsoft_Azure_Monitoring/AzureMonitoringBrowseBlade/alertsV2',
		icon: BellAlertIcon,
		current: false,
		external: true,
	},
	{
		name: 'Metrics',
		href: 'https://portal.azure.com/#@brighthr.co.uk/dashboard/arm/subscriptions/6d00831d-fe79-4ad0-8627-f1d4458bb32a/resourcegroups/dashboards/providers/microsoft.portal/dashboards/82268a58-e36d-4f7f-b343-e9396b5ce6f8',
		icon: ChartBarIcon,
		current: false,
		external: true,
	},
	{
		name: 'Kibana',
		href: 'https://f8032cd1947f42599963e621bf58e05b.eu-west-1.aws.found.io:9243',
		icon: ListBulletIcon,
		current: false,
		external: true,
	},
	{
		name: 'API',
		href: 'https://brighthr.github.io/slate',
		icon: CodeBracketIcon,
		current: false,
		external: true,
	},
	// {
	// 	name: 'Developer Docs',
	// 	href: 'https://developer.brighthr.com',
	// 	icon: DocumentTextIcon,
	// 	current: false,
	//  external: true,
	// },
];

export default function Layout({ children }) {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

	return (
		<>
			<div className="flex min-h-screen">
				{/* Narrow sidebar */}
				<NavDesktop navItems={navItems} />

				{/* Mobile menu */}
				<NavMobile
					mobileMenuOpen={mobileMenuOpen}
					setMobileMenuOpen={setMobileMenuOpen}
					navItems={navItems}
				/>

				{/* Content area */}
				<div className="flex flex-col flex-1 overflow-hidden bg-gray-50 md:ml-28">
					<Header setMobileMenuOpen={setMobileMenuOpen} />

					{/* Main content */}
					{children}
				</div>
			</div>
		</>
	);
}
