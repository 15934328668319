import React from 'react';

export default ({ links, monitoring }) =>
	(links?.length > 0 || monitoring?.dashboards?.length > 0) && (
		<section className="p-6 overflow-hidden bg-white rounded-lg shadow">
			<h2 className="mb-4 text-lg font-medium text-gray-900" id="announcements-title">
				Quick Links
			</h2>
			<div className="grid grid-cols-1 gap-y-4 lg:grid-cols-2">
				{monitoring?.dashboards?.map((item) => (
					<div
						key={item.url}
						className="relative flex items-center px-4 py-3 space-x-3 bg-white border-l-2 border-gray-300"
					>
						<a
							target="_blank"
							href={item.url}
							className="text-gray-800 hover:cursor-pointer focus:outline-none"
							rel="noreferrer"
						>
							<div className="font-semibold">{item.name}</div>
						</a>
					</div>
				))}
				{links?.map((item) => (
					<div
						key={item.url}
						className="relative flex items-center px-4 py-3 space-x-3 bg-white border-l-2 border-gray-300"
					>
						<a
							target="_blank"
							href={item.url}
							className="text-gray-800 hover:cursor-pointer focus:outline-none"
							rel="noreferrer"
						>
							<div className="font-semibold">{item.name}</div>
							<div className="pt-1 text-sm text-gray-600 line-clamp-2">
								{item.description}
							</div>
						</a>
					</div>
				))}
			</div>
		</section>
	);
