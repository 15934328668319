import { React, Fragment } from 'react';
import { Dialog, Transition, Tab } from '@headlessui/react';
import { IoReload, IoStopwatch, IoGlobeOutline, IoTrailSignOutline } from 'react-icons/io5';
import moment from 'moment';
import classnames from 'classnames';
import { IconForStatus } from '../HealthIcons';
import './index.css';

const Modal = ({ isOpen, setIsOpen, health }) => {
	const name = health.name.split('|')[0];
	const region = health.name.split('|')[1];
	const purpose = health.name.split('|')[2];

	return (
		<Transition.Root show={isOpen}>
			<Dialog as="div" className="fixed inset-0 z-40 overflow-y-auto" onClose={setIsOpen}>
				<div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
					</Transition.Child>
					<Transition.Child
						enter="ease-out duration-300"
						enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						enterTo="opacity-100 translate-y-0 sm:scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 translate-y-0 sm:scale-100"
						leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					>
						<div className="inline-block w-3/4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle">
							<div className="modal-header">
								<div>
									<h4 className="text-2xl modal-title">{name}</h4>
									<div className="flex items-center justify-start modal-subtitle">
										<IoTrailSignOutline className="mr-1" /> {region} | {purpose}
									</div>
									<div className="flex items-center justify-start modal-subtitle">
										<IoGlobeOutline className="mr-1" />{' '}
										<a
											className="modal-subtitle-sm"
											href={health.uri}
											target="_blank"
											title="Opens in new tab"
											rel="noreferrer"
										>
											{health.uri}
										</a>
									</div>
								</div>
								<div className="text-right">
									<h4 className="flex items-center justify-end text-2xl modal-title">
										<IconForStatus
											className="mr-2"
											status={health.status}
											primary={purpose === 'Primary'}
											title={health.status}
										/>{' '}
										{health.status}
									</h4>
									<div className="flex items-center justify-end modal-subtitle">
										<IoStopwatch className="mr-1" /> Since:{' '}
										{moment(health.onStateFrom).format('D-MMM-YY HH:mm:ss')}
									</div>
									<div className="flex items-center justify-end modal-subtitle">
										<IoReload className="mr-1" /> Last updated:{' '}
										{moment(health.lastExecuted).format('D-MMM-YY HH:mm:ss')}
									</div>
								</div>
							</div>
							<div className="modal-body">
								<Tab.Group>
									<Tab.List className="flex px-1.5 py-3 space-x-1 rounded-md bg-gray-200">
										<Tab>
											{({ selected }) => (
												<span
													className={classnames('px-3 py-2 rounded-md', {
														'bg-white': selected,
													})}
												>
													Health Checks
												</span>
											)}
										</Tab>
										<Tab>
											{({ selected }) => (
												<span
													className={classnames('px-3 py-2 rounded-md', {
														'bg-white': selected,
													})}
												>
													History
												</span>
											)}
										</Tab>
									</Tab.List>
									<Tab.Panels className="mt-4">
										<Tab.Panel>
											<table className="w-full health-table health-table-sm">
												<thead>
													<tr>
														<th>Status</th>
														<th>Name</th>
														<th>Duration</th>
														<th>Description</th>
													</tr>
												</thead>
												<tbody>
													{health.entries.map((check) => (
														<Row key={check} check={check} />
													))}
												</tbody>
											</table>
										</Tab.Panel>
										<Tab.Panel>
											<div className="w-full history">
												<div className="timeline">
													{health.history.map((history) => (
														<TimelineElement
															key={history}
															history={history}
														/>
													))}
												</div>
											</div>
										</Tab.Panel>
									</Tab.Panels>
								</Tab.Group>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

const TimelineElement = ({ history }) => (
	<div className="timeline-element">
		<span className="timeline-element-icon">
			<IconForStatus status={history.status} primary title={history.status} />
		</span>
		<div
			className={classnames('timeline-element-content', {
				[`timeline-element-content-${history.status}`]: true,
			})}
		>
			<div className="timeline-element-content-arrow" />
			<div className="timeline-element-status">{history.name}</div>
			<div className="timeline-element-description">{history.description}</div>
			<span className="timeline-element-date">
				{moment(history.on).format('D-MMM-YY HH:mm:ss')}
			</span>
		</div>
	</div>
);

const Row = ({ check }) => {
	const duration = moment.duration(check.duration);
	return (
		<tr>
			<td>
				<IconForStatus primary status={check.status} title={check.status} />
			</td>
			<td>{check.name}</td>
			<td>
				{duration.seconds() === 0 ? (
					`${duration.asMilliseconds()} ms`
				) : (
					<span className="text-danger">{duration.asSeconds()} s</span>
				)}
			</td>
			<td>{check.description}</td>
		</tr>
	);
};

export default Modal;
