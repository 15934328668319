import React from 'react';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import PageTitle from '../../PageTitle';

export default ({ service }) => (
	<section className="overflow-hidden bg-white rounded-lg shadow">
		<div className="p-6 bg-white">
			<div className="sm:flex sm:items-center sm:justify-between">
				<div className="mr-2 sm:flex sm:space-x-5">
					<div className="mt-4 text-center sm:mt-0 sm:text-left">
						<PageTitle>{service.name}</PageTitle>
						{service.description && <div className="mt-2">{service.description}</div>}
					</div>
				</div>
				<div className="flex justify-center mt-5 sm:mt-0">
					<a
						target="_blank"
						href={service.repository.url ?? service.repository.urlExternal}
						className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm whitespace-nowrap hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
						rel="noreferrer"
					>
						Edit catalog.yml
						<ArrowTopRightOnSquareIcon className="w-4 h-4 ml-2" />
					</a>
				</div>
			</div>
		</div>
		<div className="grid grid-cols-1 border-t border-gray-200 divide-y divide-gray-200 bg-gray-50 sm:grid-cols-3 sm:divide-y-0 sm:divide-x">
			<div className="px-6 py-3 text-sm font-medium text-center">
				<span className="text-gray-900">Component:</span>{' '}
				<span className="font-bold text-gray-600 lowercase">{service.component}</span>
			</div>
			<div className="px-6 py-3 text-sm font-medium text-center">
				<span className="text-gray-900">Languages:</span>{' '}
				<span className="font-bold text-gray-600 lowercase">
					{service.repository.languages?.map((language) => `${language} `)}
				</span>
			</div>
			<div className="px-6 py-3 text-sm font-medium text-center">
				<span className="text-gray-900">Lifecycle:</span>{' '}
				<span className="font-bold text-gray-600 lowercase">{service.lifecycle}</span>
			</div>
		</div>
	</section>
);
