import React from 'react';
import HealthTableCell from '../HealthTableCell';

const HealthTableRow = ({ serviceName, serviceHealth }) => {
	function gethealth(svcHealth, region) {
		return svcHealth.find((x) => x.name.split('|')[1] === region);
	}

	return (
		<tr>
			<td className="highlight">{serviceName}</td>
			<HealthTableCell health={gethealth(serviceHealth, 'UK South')} />
			<HealthTableCell
				health={serviceHealth.find((x) => x.name.split('|')[1] === 'UK West')}
			/>
			<HealthTableCell
				health={serviceHealth.find((x) => x.name.split('|')[1] === 'North Europe')}
			/>
			<HealthTableCell
				health={serviceHealth.find((x) => x.name.split('|')[1] === 'West Europe')}
			/>
			<HealthTableCell
				health={serviceHealth.find((x) => x.name.split('|')[1] === 'Canada Central')}
			/>
			<HealthTableCell
				health={serviceHealth.find((x) => x.name.split('|')[1] === 'Canada East')}
			/>
			<HealthTableCell
				health={serviceHealth.find((x) => x.name.split('|')[1] === 'Australia East')}
			/>
			<HealthTableCell
				health={serviceHealth.find((x) => x.name.split('|')[1] === 'Australia SouthEast')}
			/>
		</tr>
	);
};

export default HealthTableRow;
