import React from 'react';
import Layout from '../../components/Layout/SingleColumnLayout';
import ServiceItem from '../../components/ServiceItem';

const Service = () => (
	<Layout>
		<ServiceItem />
	</Layout>
);

export default Service;
