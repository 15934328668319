import React from 'react';
import { HealthyIcon, DegradedIcon, UnhealthyIcon } from '../HealthIcons';

const KeyBlock = ({ children }) => <div className="mr-3">{children}</div>;

const KeyBlockItem = ({ icon, description }) => (
	<div className="flex items-center">
		<div className="mr-1 mb-0.5">{icon}</div>
		<span className="text-xs">{description}</span>
	</div>
);

const Key = () => (
	<div className="flex justify-start mb-6">
		<KeyBlock>
			<KeyBlockItem icon={<HealthyIcon primaryRegion />} description="Healthy (Primary)" />
			<KeyBlockItem
				icon={<HealthyIcon primaryRegion={false} />}
				description="Healthy (Secondary)"
			/>
		</KeyBlock>
		<KeyBlock>
			<KeyBlockItem icon={<DegradedIcon primaryRegion />} description="Degraded (Primary)" />
			<KeyBlockItem
				icon={<DegradedIcon primaryRegion={false} />}
				description="Degraded (Secondary)"
			/>
		</KeyBlock>
		<KeyBlock>
			<KeyBlockItem
				icon={<UnhealthyIcon primaryRegion />}
				description="Unhealthy (Primary)"
			/>
			<KeyBlockItem
				icon={<UnhealthyIcon primaryRegion={false} />}
				description="Unhealthy (Secondary)"
			/>
		</KeyBlock>
	</div>
);

export default Key;
