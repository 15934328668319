import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

export default ({ serviceName, slug }) => (
	<div className="mb-4">
		<nav className="sm:hidden" aria-label="Back">
			<Link
				to="/services"
				className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700"
			>
				<ChevronLeftIcon
					className="flex-shrink-0 w-5 h-5 mr-1 -ml-1 text-gray-400"
					aria-hidden="true"
				/>
				Back
			</Link>
		</nav>
		<nav className="hidden sm:flex" aria-label="Breadcrumb">
			<ol className="flex items-center space-x-4">
				<li>
					<div className="flex">
						<Link
							to="/"
							className="text-sm font-medium text-gray-500 hover:text-gray-700"
						>
							Home
						</Link>
					</div>
				</li>
				<li>
					<div className="flex items-center">
						<ChevronRightIcon
							className="flex-shrink-0 w-5 h-5 text-gray-400"
							aria-hidden="true"
						/>
						<Link
							to="/services"
							className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
						>
							Services
						</Link>
					</div>
				</li>
				<li>
					<div className="flex items-center">
						<ChevronRightIcon
							className="flex-shrink-0 w-5 h-5 text-gray-400"
							aria-hidden="true"
						/>
						<Link
							to={`/services/${slug}`}
							aria-current="page"
							className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
						>
							{serviceName}
						</Link>
					</div>
				</li>
			</ol>
		</nav>
	</div>
);
