import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import getConfig from '../../utils/getConfig';

const { apiScopes, apiUrl } = getConfig();

const useApi = () => {
	const { instance } = useMsal();

	const getAccessToken = () => {
		const accounts = instance.getAllAccounts();
		const request = { scopes: apiScopes, account: accounts[0] };
		return instance
			.acquireTokenSilent(request)
			.then((response) => response.accessToken)
			.catch(async (error) => {
				console.error(`Error getting access token: ${error}`);
				if (error instanceof InteractionRequiredAuthError) {
					await instance.acquireTokenRedirect(request);
				}
				throw error;
			});
	};

	const baseRequest = (relativePath, options) =>
		getAccessToken(instance).then((token) => {
			const headers = new Headers();
			headers.append('Authorization', `Bearer ${token}`);
			return fetch(`${apiUrl}${relativePath}`, { ...options, headers })
				.then((response) => response.json())
				.catch((error) => console.error(error));
		});

	const get = (relativePath, options = {}) =>
		baseRequest(relativePath, {
			...options,
			method: 'get',
		});

	const post = (relativePath, options = {}) =>
		baseRequest(relativePath, {
			...options,
			method: 'post',
		});

	return { get, post };
};

export default useApi;
