import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout/SingleColumnLayout';
import HealthTable from '../../components/Health/HealthTable';
import Key from '../../components/Health/Key';
import PageTitle from '../../components/PageTitle';
import useApi from '../../hooks/useApi';

const Health = () => {
	const { get } = useApi();
	const [loading, setLoading] = useState(true);
	const [apiData, setApiData] = useState(null);

	useEffect(() => {
		if (!apiData) {
			get('/v1/catalog/health').then((response) => {
				setApiData(response);
				setLoading(false);
			});
		}
	}, [get, apiData]);

	const contents = loading ? (
		<div className="text-center">
			<em>Loading health checks...</em>
		</div>
	) : (
		<>
			<HealthTable data={apiData} />
		</>
	);

	return (
		<Layout>
			<PageTitle className="mb-6">Health</PageTitle>
			<Key />
			{contents}
		</Layout>
	);
};

export default Health;
