import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import Home from './pages/Home';
import Services from './pages/Services';
import Service from './pages/Service';
import Health from './pages/Health';
import Settings from './pages/Settings';
import Login from './pages/Login';
import NotFound from './pages/NotFound';

const App = ({ msalInstance }) => (
	<BrowserRouter>
		<MsalProvider instance={msalInstance}>
			<AuthenticatedTemplate>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/services" element={<Services />} />
					<Route path="/services/*" element={<Service />} />
					<Route path="/health" element={<Health />} />
					<Route path="/settings" element={<Settings />} />
					<Route path="/login" element={<Navigate to="/" replace />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<Routes>
					<Route path="/login" element={<Login />} />
					<Route path="*" element={<Navigate to="/login" replace />} />
				</Routes>
			</UnauthenticatedTemplate>
		</MsalProvider>
	</BrowserRouter>
);

export default App;
