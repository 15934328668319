import React from 'react';
import Layout from '../../components/Layout/SingleColumnLayout';
import PageTitle from '../../components/PageTitle';
import ServiceList from '../../components/ServiceList';

const Services = () => (
	<Layout>
		<PageTitle className="mb-8">Services</PageTitle>
		<ServiceList />
	</Layout>
);

export default Services;
