import React from 'react';
import Layout from '../../components/Layout/SingleColumnLayout';
import PageTitle from '../../components/PageTitle';

const Home = () => (
	<Layout>
		<PageTitle>BrightHR Software Catalog</PageTitle>
	</Layout>
);

export default Home;
