import React from 'react';
import { SiGithub } from 'react-icons/si';

export default ({ repository }) => (
	<section>
		<div className="p-6 overflow-hidden bg-white rounded-lg shadow">
			<div className="-my-5 divide-y divide-gray-200">
				<div className="py-4">
					<a
						href={repository.url ?? repository.urlExternal}
						target="_blank"
						className="flex items-center"
						rel="noreferrer"
					>
						<div className="flex-shrink-0">
							{repository.type === 'GitHub' && (
								<SiGithub className="w-8 h-8 mr-4 rounded-full" />
							)}
						</div>
						<div className="flex-1 min-w-0">
							<p className="text-base font-medium text-gray-900">
								{repository.owner}/{repository.name}
								{repository.rootFolder && ` (folder: ${repository.rootFolder})`}
							</p>
							<p className="text-sm text-gray-500">
								{repository.url ?? repository.urlExternal}
							</p>
						</div>
					</a>
				</div>
			</div>
		</div>
	</section>
);
