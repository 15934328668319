import { React, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import {
	XMarkIcon,
	ArrowRightOnRectangleIcon,
	ArrowTopRightOnSquareIcon,
} from '@heroicons/react/24/outline';
import { useMsal } from '@azure/msal-react';

const NavMobile = ({ mobileMenuOpen, setMobileMenuOpen, navItems }) => {
	const { instance } = useMsal();
	return (
		<Transition.Root show={mobileMenuOpen} as={Fragment}>
			<Dialog as="div" className="relative z-20 md:hidden" onClose={setMobileMenuOpen}>
				<Transition.Child
					as={Fragment}
					enter="transition-opacity ease-linear duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="transition-opacity ease-linear duration-300"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
				</Transition.Child>

				<div className="fixed inset-0 z-40 flex">
					<Transition.Child
						as={Fragment}
						enter="transition ease-in-out duration-300 transform"
						enterFrom="-translate-x-full"
						enterTo="translate-x-0"
						leave="transition ease-in-out duration-300 transform"
						leaveFrom="translate-x-0"
						leaveTo="-translate-x-full"
					>
						<Dialog.Panel className="relative flex flex-col flex-1 w-full max-w-xs pt-5 pb-4 bg-indigo-700">
							<Transition.Child
								as={Fragment}
								enter="ease-in-out duration-300"
								enterFrom="opacity-0"
								enterTo="opacity-100"
								leave="ease-in-out duration-300"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<div className="absolute right-0 p-1 top-1 -mr-14">
									<button
										type="button"
										className="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none focus:ring-2 focus:ring-white"
										onClick={() => setMobileMenuOpen(false)}
									>
										<XMarkIcon
											className="w-6 h-6 text-white"
											aria-hidden="true"
										/>
										<span className="sr-only">Close sidebar</span>
									</button>
								</div>
							</Transition.Child>
							<div className="flex-1 h-0 px-2 mt-5 overflow-y-auto">
								<nav className="flex flex-col h-full">
									<div className="space-y-1">
										{navItems.map((item) => (
											<Link
												key={item.name}
												to={item.href}
												onClick={() => setMobileMenuOpen(false)}
												target={item.external ? '_blank' : '_self'}
												className={classNames(
													item.current
														? 'bg-indigo-800 text-white'
														: 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
													'group py-2 px-3 rounded-md flex items-center text-sm font-medium'
												)}
												aria-current={item.current ? 'page' : undefined}
												rel="noreferrer"
											>
												<item.icon
													className={classNames(
														item.current
															? 'text-white'
															: 'text-indigo-300 group-hover:text-white',
														'mr-3 h-6 w-6'
													)}
													aria-hidden="true"
												/>
												<span className="flex items-center">
													{item.name}
													{item.external && (
														<ArrowTopRightOnSquareIcon className="w-4 h-4 ml-1" />
													)}
												</span>
											</Link>
										))}
									</div>
									<button
										type="submit"
										className="flex items-center px-3 py-2 mt-1 text-sm font-medium rounded-md group'flex w-full p-3 text-center text-indigo-100 hover:bg-indigo-800 hover:text-white group"
										onClick={() =>
											instance.logoutRedirect({
												onRedirectNavigate: () =>
													// Return false if you would like to stop navigation to AAD logout after local logout
													false,
											})
										}
									>
										<ArrowRightOnRectangleIcon className="w-6 h-6 mr-3 text-indigo-300 group-hover:text-white" />
										<span>Logout</span>
									</button>
								</nav>
							</div>
						</Dialog.Panel>
					</Transition.Child>
					<div className="flex-shrink-0 w-14" aria-hidden="true">
						{/* Dummy element to force sidebar to shrink to fit close icon */}
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default NavMobile;
