import React from 'react';
import classNames from 'classnames';

const PageTitle = ({ className, children }) => (
	<h1
		className={classNames(
			className,
			'text-3xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'
		)}
	>
		{children}
	</h1>
);

export default PageTitle;
