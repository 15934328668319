import React from 'react';
import ProfilePic from '../../../images/profile-pic-default.jpg';

export default ({ contacts }) =>
	contacts?.length > 0 && (
		<section>
			<div className="overflow-hidden bg-white rounded-lg shadow">
				<div className="p-6">
					<h2 className="text-lg font-medium text-gray-900" id="recent-hires-title">
						Other Contacts
					</h2>
					<div className="flow-root mt-6">
						<ul className="-my-5 divide-y divide-gray-200">
							{contacts.map((contact) => (
								<li key={contact.name} className="py-4">
									<div className="flex items-center space-x-4">
										<div className="flex-shrink-0">
											<img
												className="w-8 h-8 rounded-full"
												src={ProfilePic}
												alt=""
											/>
										</div>
										<div className="flex-1 min-w-0">
											<p className="text-sm font-medium text-gray-900">
												{contact.name}
											</p>
											<p className="text-sm text-gray-500">
												{contact.description}
											</p>
										</div>
									</div>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
