import React from 'react';
import { CopyBlock, hybrid } from 'react-code-blocks';
import classNames from 'classnames';
import Layout from '../../components/Layout/SingleColumnLayout';
import PageTitle from '../../components/PageTitle';

const FullExample = `services:
- name: Checkout Web UI
  uid: 01
  description: The web interface for processing digital payments
  component: web
  lifecycle: production
  notes: This web app was originally built by an external consultancy
  owner:
    team: blue
    email: [blue-team@grr.la,backup-team@grr.la]
    slack:
      channel_name: blue-team
      channel_id: CN55XR9AQ
  repository:
    languages: [csharp, typescript]
    root_folder: /api
    url_external: https://bitbucket.com/something
  issues:
    jira:
      board_url: https://jira.com
      project_id: BLU
      components: [web, data]
  deployment:
    azure_devops:
      project_name: Checkout Web UI
    netlify:
      something: something
    cloudflare:
      something: something
  hosting:
    domains: [https://example.com/v1/example]
    platform: azure
    health_checks:
    - url: https://bright-example-uks.azurewebsites.net/healthz
      region: uk south
      type: active
    - url: https://bright-example-cac.azurewebsites.net/healthz
      region: canada central
      type: passive
  monitoring:
    app_insights:
      application_id: abc123def
      url: https://portal.azure.com/somewhere
    trackjs:
      something: something
    crashlytics:
      something: something
    kibana:
      something: something
    alerts:
      something: something
    dashboards:
    - name: Azure Dashboard
      url: https://portal.azure.com/somewhere
  contacts:
  - name: Chris Wareing
    description: Director of Marketing, BrightHR. Sponsor for the project
  - name: Alex Maher
    description: Director of Technology, BrightHR
  links:
  - name: Project inception Miro board
    url: https://miro.com
    description: Notes on the project inception session
  - name: Roadmap
    url: https://trello.com`;

// // tech stack:
// // - something: here
// // data:
// //   datocms:
// //  	url:
// // tags:
// // - key: database
// //   value: cosmos
// // - key: service-bus-topic
// //   value: cart-additions
// // handles_pii: true

const H2 = ({ children, id }) => (
	<h2 id={id} className="mt-10 mb-3 text-2xl">
		{children}
	</h2>
);
const H3 = ({ children, id }) => (
	<h3 id={id} className="mt-10 mb-3 text-xl">
		{children}
	</h3>
);
const P = ({ children }) => <p className="mb-3">{children} </p>;
const CodeInline = ({ children }) => (
	<code className="px-1 py-0.5 bg-gray-200 rounded-sm">{children}</code>
);
const YamlBlock = ({ code }) => (
	<CopyBlock
		language="yaml"
		theme={hybrid}
		text={code}
		showLineNumbers={false}
		wrapLongLines={false}
		customStyle={{
			maxWidth: '896px',
			marginBottom: '12px',
			padding: '8px',
			borderRadius: '8px',
			fontSize: '0.8rem',
		}}
	/>
);
const Table = ({ children }) => (
	<div className="flex flex-col max-w-4xl mb-4">
		<div className="inline-block min-w-full align-middle">
			<div className="overflow-hidden rounded-md shadow ring-1 ring-black ring-opacity-5">
				<table className="min-w-full divide-y divide-gray-300">
					<thead className="bg-gray-50">
						<tr>
							<th
								scope="col"
								className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
							>
								Key
							</th>
							<th
								scope="col"
								className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
							>
								Type
							</th>
							<th
								scope="col"
								className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
							>
								Required
							</th>
							<th
								scope="col"
								className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
							>
								Details
							</th>
						</tr>
					</thead>
					<tbody className="bg-white divide-y divide-gray-200">{children}</tbody>
				</table>
			</div>
		</div>
	</div>
);
const TableRow = ({ name, type, typeLink, required, details, child }) => (
	<tr>
		<td className="py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 whitespace-nowrap">
			<span className={classNames(child ? 'pl-3 border-l border-gray-300' : '')}>{name}</span>
		</td>
		<td className="px-3 py-2 text-sm text-gray-500 whitespace-nowrap">
			{typeLink ? (
				<a className="text-indigo-700 hover:underline" href={typeLink}>
					{type}
				</a>
			) : (
				type
			)}
		</td>
		<td className="px-3 py-2 text-sm text-gray-500 whitespace-nowrap">
			{required ? 'yes' : 'no'}
		</td>
		<td className="px-3 py-2 text-sm text-gray-500">{details}</td>
	</tr>
);

const ServiceCode = `- name: Checkout Web UI
  uid: 01
  description: The web interface for processing digital payments
  component: web
  lifecycle: production
  notes: This web app was originally built by an external consultancy`;
const OwnerCode = `owner:
  team: blue
  email: [blue-team@grr.la,backup-team@grr.la]
  slack:
    channel_name: blue-team
    channel_id: CN55XR9AQ`;
const RepositoryCode = `repository:
  languages: [csharp, typescript]
  root_folder: /api
  url_external: https://bitbucket.com/something`;
const IssuesCode = `issues:
  jira:
    board_url: url.com
    project_id: INT
    components: [api, data]`;
const DeploymentCode = `deployment:
  azure_devops:
    project_name: Recruitment Api
  netlify:
    something: something
  cloudflare:
    something: something`;
const HostingCode = `hosting:
  domains: [https://example.com/v1/example]
  platform: azure
  health_checks:
  - url: https://bright-example-uks.azurewebsites.net/healthz
    region: uk south
    type: active
  - url: https://bright-example-cac.azurewebsites.net/healthz
    region: canada central
    type: passive`;
const MonitoringCode = `monitoring:
  app_insights:
    application_id: abc123def
    url: https://portal.azure.com/somewhere
  trackjs:
    something: something
  crashlytics:
    something: something
  kibana:
    something: something
  alerts:
    something: something
  dashboards:
  - name: Azure Dashboard
    url: https://portal.azure.com/somewhere`;
const ContactsCode = `contacts:
- name: Chris Wareing
  description: Director of Marketing, BrightHR. Sponsor for the project
- name: Alex Maher
  description: Director of Technology, BrightHR`;
const LinksCode = `links:
- name: Project inception Miro board
  url: https://miro.com
  description: Notes on the project inception session
- name: Roadmap
  url: https://trello.com`;

const Settings = () => (
	<Layout>
		<PageTitle className="mb-6">Settings</PageTitle>
		<P>
			To index your service in the catalog, drop a <CodeInline>catalog.yml</CodeInline> file
			at the root of your{' '}
			<a
				href="https://github.com/brighthr"
				target="_blank"
				rel="noreferrer"
				className="text-indigo-700 hover:underline"
			>
				BrightHR GitHub
			</a>{' '}
			repository and push this into your main/master branch. The schema reference for the YAML
			file is defined below.
		</P>
		<P>
			If the file contains any invalid syntax, or if there are any unsupported values
			provided, the indexing process will fail. Errors can be found in the{' '}
			<a
				href="https://portal.azure.com/#@brighthr.co.uk/resource/subscriptions/6d00831d-fe79-4ad0-8627-f1d4458bb32a/resourceGroups/bright-catalog-prod/providers/microsoft.insights/components/bright-api-catalog-prod/failures"
				target="_blank"
				rel="noreferrer"
				className="text-indigo-700 hover:underline"
			>
				Failed Operations in Application Insights
			</a>
			.
		</P>
		<P>
			<span className="font-bold">TL;DR</span> - See the{' '}
			<a className="text-indigo-700 hover:underline" href="#example">
				Full Example
			</a>{' '}
			below.
		</P>
		<H2 id="schema">Schema Definition</H2>
		<P>
			The top-level key <CodeInline>services</CodeInline> is an array of service templates
			(below), allowing the definition of multiple services in a single repository.
		</P>
		<YamlBlock
			code={`services:
- {service template}
- {service template}`}
		/>
		<H3 id="service">Service Template</H3>
		<P>
			This template defines an item in the catalog. Each item should represent a deployable
			software system, e.g. separate entries for a Web UI and an API.
		</P>
		<YamlBlock
			code={`${ServiceCode}
  owner: {owner template}
  repository: {repository template}
  issues: {issues template}
  deployment: {deployment template}
  hosting: {hosting template}
  monitoring: {monitoring template}
  contacts: {contacts template}
  links: {links template}
`}
		/>
		<Table>
			<TableRow name="name" type="string" required details="Name of the service" />
			<TableRow
				name="uid"
				type="integer"
				required
				details="A unique identifier for the service within the scope of the catalog.yml file. Must remain consistent once set for updates to be applied correctly"
			/>
			<TableRow
				name="description"
				type="string"
				required
				details="Description of the service's responsibilities"
			/>
			<TableRow
				name="component"
				type="string"
				required
				details="The type of service. Supported values: api, data, mobile, web"
			/>
			<TableRow
				name="lifecycle"
				type="string"
				required
				details="Delivery lifecycle stage. Supported values: labs, production, maintenance"
			/>
			<TableRow
				name="notes"
				type="string"
				details="Some optional notes to provide any additional context"
			/>
			<TableRow
				name="owner"
				type="owner"
				typeLink="#owner"
				required
				details="The owning team who is responsible for the service"
			/>
			<TableRow
				name="repository"
				type="repository"
				typeLink="#repository"
				details="Where the source code is hosted. Defaults to BrightHR GitHub, as provided via webhooks"
			/>
			<TableRow
				name="issues"
				type="issues"
				typeLink="#issues"
				details="Project management system, issues backlog and bug tracking"
			/>
			<TableRow
				name="deployment"
				type="deployment"
				typeLink="#deployment"
				details="Build and deployment pipelines"
			/>
			<TableRow
				name="hosting"
				type="hosting"
				typeLink="#hosting"
				details="The hosting provider, deployed locations and domain names"
			/>
			<TableRow
				name="monitoring"
				type="monitoring"
				typeLink="#monitoring"
				details="Operational monitoring of the service, alerting and dashboards"
			/>
			<TableRow
				name="contacts"
				type="contacts"
				typeLink="#contact"
				details="Useful contacts, stakeholders or sponsors relating to the service"
			/>
			<TableRow
				name="links"
				type="links"
				typeLink="#link"
				details="Any links that are not covered by the above categories"
			/>
		</Table>
		<H3 id="owner">Owner Template</H3>
		<YamlBlock code={OwnerCode} />
		<Table>
			<TableRow name="team" type="string" required details="The name of the owning team" />
			<TableRow
				name="email"
				type="array of strings"
				details="Contact email addresses for the team"
			/>
			<TableRow name="slack" type="object" required />
			<TableRow
				name="channel_name"
				type="string"
				required
				child
				details="Name of the team's comms channel in BrightHR Slack"
			/>
			<TableRow
				name="channel_id"
				type="string"
				required
				child
				details="ID of the team's comms channel in BrightHR Slack - Right click on channel and Copy -> CopyLink to extract the channel ID"
			/>
		</Table>
		<H3 id="repository">Repository Template</H3>
		<YamlBlock code={RepositoryCode} />
		<Table>
			<TableRow
				name="languages"
				type="array of strings"
				details="A list of the languages used within the service, e.g. c#, typescript, swift, kotlin, dart. Note: Enclose 'c#' in single quotes"
			/>
			<TableRow
				name="root_folder"
				type="string"
				details="Relative path of the service's root folder within the repository. Use when multiple services are defined in a single repository. Defaults to '/'"
			/>
			<TableRow
				name="url_external"
				type="string"
				details="The repository URL, to be used when code is not hosted in BrightHR's GitHub organisation"
			/>
		</Table>
		<H3 id="issues">Issues Template</H3>
		<YamlBlock code={IssuesCode} />
		<Table>
			<TableRow name="jira" type="object" />
			<TableRow
				name="board_url"
				type="string"
				child
				details="The URL to the project board for issue tracking"
			/>
			<TableRow
				name="project_id"
				type="string"
				child
				details="The ID of the project, visible in the URL. Helps with Jira API interactions"
			/>
			<TableRow
				name="components"
				type="array of strings"
				child
				details="A list of the component names to filter on the jira project board"
			/>
		</Table>
		<H3 id="deployment">Deployment Template</H3>
		<YamlBlock code={DeploymentCode} />
		<Table>
			<TableRow name="azure_devops" type="object" />
			<TableRow
				name="project_name"
				type="string"
				child
				details="The name of the project in BrightHR's Azure DevOps organisation"
			/>
			<TableRow name="netlify" type="object" details="Not yet defined" />
			<TableRow name="cloudflare" type="object" details="Not yet defined" />
		</Table>
		<H3 id="hosting">Hosting Template</H3>
		<YamlBlock code={HostingCode} />
		<Table>
			<TableRow
				name="domains"
				type="array of strings"
				details="The domain names and relative paths used by the service"
			/>
			<TableRow
				name="platform"
				type="string"
				details="The name of the hosting platform, e.g. azure, cloudflare, netlify, gcp, digitalocean, internal vm"
			/>
			<TableRow
				name="health_checks"
				type="array of objects"
				details="A list of monitorsendpoints where deployed services can be monitored"
			/>
			<TableRow
				name="url"
				type="string"
				required
				child
				details="A list of endpoints where deployed services can be monitored"
			/>
			<TableRow
				name="region"
				type="string"
				required
				child
				details="The deployed region, e.g. UK South - do not abbreviate. Use 'global' for global CDNs"
			/>
			<TableRow
				name="type"
				type="string"
				required
				child
				details="Whether the deployment is Active (primary) or Passive (secondary/failover) in nature"
			/>
		</Table>
		<H3 id="monitoring">Monitoring Template</H3>
		<YamlBlock code={MonitoringCode} />
		<Table>
			<TableRow name="app_insights" type="object" />
			<TableRow
				name="url"
				type="string"
				required
				child
				details="The Azure Portal URL for the App Insights resource"
			/>
			<TableRow
				name="application_id"
				type="string"
				required
				child
				details="To support API access to App Insights. Application ID can be found in the 'API Access' menu item in the App Insights resource"
			/>
			<TableRow name="trackjs" type="object" details="Not yet defined" />
			<TableRow name="crashlytics" type="object" details="Not yet defined" />
			<TableRow name="kibana" type="object" details="Not yet defined" />
			<TableRow name="alerts" type="object" details="Not yet defined" />
			<TableRow name="dashboards" type="array of objects" />
			<TableRow
				name="name"
				type="string"
				required
				child
				details="A short name for the dashboard link"
			/>
			<TableRow name="url" type="string" required child details="URL of the dashboard" />
		</Table>
		<H3 id="contact">Contact Template</H3>
		<YamlBlock code={ContactsCode} />
		<Table>
			<TableRow name="contacts" type="array of objects" />
			<TableRow name="name" type="string" required child details="Name of the contact" />
			<TableRow
				name="description"
				type="string"
				child
				details="Description of who the contact is, their department/job title and why they are relevant to the service"
			/>
		</Table>
		<H3 id="link">Link Template</H3>
		<YamlBlock code={LinksCode} />
		<Table>
			<TableRow name="links" type="array of objects" />
			<TableRow
				name="name"
				type="string"
				required
				child
				details="Short name for the link, used for the anchor text"
			/>
			<TableRow name="url" type="string" required child details="The URL" />
			<TableRow
				name="description"
				type="string"
				child
				details="Optional description of what exists at the link destination"
			/>
		</Table>
		<H2 id="example">Full Example</H2>
		<YamlBlock code={FullExample} />
	</Layout>
);

export default Settings;
