import React, { useState } from 'react';
import { HealthyIcon, DegradedIcon, UnhealthyIcon } from '../HealthIcons';
import DetailsModal from '../Modal';

const HealthTableCell = ({ health }) => {
	const [isOpen, setIsOpen] = useState(false);

	if (health === undefined) {
		return <td />;
	}

	const title = `${health.status} since ${health.onStateFrom}`;
	const primaryRegion = health.name.split('|')[2] === 'Primary';

	const icon = {
		Unhealthy: <UnhealthyIcon primaryRegion={primaryRegion} />,
		Degraded: <DegradedIcon primaryRegion={primaryRegion} />,
	}[health.status] || <HealthyIcon primaryRegion={primaryRegion} />;

	return (
		<td className="has-status">
			<div
				className="flex items-center justify-center text-lg status"
				title={title}
				onClick={() => setIsOpen(true)}
			>
				{icon}
			</div>
			<DetailsModal isOpen={isOpen} setIsOpen={setIsOpen} health={health} />
		</td>
	);
};

export default HealthTableCell;
