import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { Scrollbars } from 'react-custom-scrollbars-2';
import colors from 'tailwindcss/colors';
import { useMsal } from '@azure/msal-react';
import { ArrowRightOnRectangleIcon, ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { ReactComponent as Logo } from '../../images/logo.svg';

const renderTrack = ({ style }) => {
	const trackStyle = {
		height: `${window.innerHeight - 163}px`,
		width: '5px',
		borderRadius: '4px',
		right: '6px',
	};
	return <div className="ignore-react-onclickoutside" style={{ ...style, ...trackStyle }} />;
};
const renderThumb = ({ style }) => {
	const thumbStyle = {
		backgroundColor: colors.indigo[300],
		borderRadius: '4px',
	};
	return <div className="ignore-react-onclickoutside" style={{ ...style, ...thumbStyle }} />;
};

const NavDesktop = ({ navItems }) => {
	const { instance } = useMsal();

	return (
		<div className="fixed hidden h-full bg-indigo-700 w-28 md:block">
			<div className="flex flex-col items-center w-full h-full">
				<div className="flex-shrink-0 w-full px-2 text-center bg-indigo-800">
					<Link to="/">
						<Logo className="h-16 py-4 text-indigo-100" />
					</Link>
				</div>
				<div className="items-center flex-1 w-full pt-2 space-y-1 overflow-y-auto no-scrollbar">
					<Scrollbars
						autoHide
						hideTracksWhenNotNeeded
						renderThumbVertical={renderThumb}
						renderTrackVertical={renderTrack}
					>
						<div className="px-2">
							{navItems.map((item) => (
								<NavLink
									key={item.name}
									to={item.href}
									end={item.href === '/'}
									target={item.external ? '_blank' : ''}
									className={({ isActive }) =>
										classNames(
											isActive
												? 'bg-indigo-800 text-white'
												: 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
											'group w-24 p-3 mb-1 rounded-md flex flex-col items-center text-sm font-medium text-center'
										)
									}
									rel="noreferrer"
								>
									{({ isActive }) => (
										<>
											<item.icon
												className={classNames(
													isActive
														? 'text-white'
														: 'text-indigo-300 group-hover:text-white',
													'h-6 w-6'
												)}
												aria-hidden="true"
											/>
											<span className="flex items-center mt-1">
												{item.name}
												{item.external && (
													<ArrowTopRightOnSquareIcon className="w-4 h-4 ml-1" />
												)}
											</span>
										</>
									)}
								</NavLink>
							))}
						</div>
						<div
							className="h-4 bg-gradient-to-t from-indigo-800"
							style={{
								position: 'fixed',
								bottom: '76px',
								left: '0',
								width: '112px',
							}}
						/>
					</Scrollbars>
				</div>
				<div className="flex items-center flex-shrink-0 w-full px-2 pb-1 bg-indigo-800">
					<button
						type="submit"
						className="flex flex-col items-center w-full p-3 text-xs font-medium text-center text-indigo-100 rounded-md hover:bg-indigo-800 hover:text-white group"
						onClick={() =>
							instance.logoutRedirect({
								onRedirectNavigate: () =>
									// Return false if you would like to stop navigation to AAD logout after local logout
									false,
							})
						}
					>
						<ArrowRightOnRectangleIcon className="w-6 h-6 text-indigo-300 group-hover:text-white" />
						<span className="mt-1 text-sm">Logout</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default NavDesktop;
