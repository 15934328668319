import { React } from 'react';
import { useNavigate } from 'react-router-dom';
import SingleColumnLayout from '../../components/Layout/SingleColumnLayout';

const NotFound = () => {
	const navigate = useNavigate();

	return (
		<SingleColumnLayout>
			<div className="flex-shrink-0 py-16 my-auto sm:py-32">
				<p className="text-sm font-semibold tracking-wide uppercase text-brand-500">
					404 error
				</p>
				<h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
					Page not found
				</h1>
				<p className="mt-2 text-base text-gray-500">
					Sorry, we couldn’t find the page you’re looking for.
				</p>
				<div className="mt-6">
					<button
						type="button"
						onClick={() => navigate('/')}
						className="text-base font-medium text-brand-500 hover:text-brand-400"
					>
						Go back home<span aria-hidden="true"> &rarr;</span>
					</button>
				</div>
			</div>
		</SingleColumnLayout>
	);
};

export default NotFound;
