import React from 'react';
import classNames from 'classnames';
import {
	GlobeAltIcon,
	ChatBubbleBottomCenterTextIcon,
	EyeIcon,
	PaperAirplaneIcon,
} from '@heroicons/react/24/outline';

export default ({ deployment, monitoring, hosting, issues }) => (
	<section className="overflow-hidden bg-gray-200 divide-y divide-gray-200 rounded-lg shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
		<div
			className={classNames(
				'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none',
				'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
			)}
		>
			<div className="flex items-center justify-between">
				<div className="flex items-center">
					<span
						className={classNames(
							'text-sky-700',
							'bg-sky-50',
							'rounded-lg inline-flex p-3 ring-4 ring-white mr-4'
						)}
					>
						<PaperAirplaneIcon className="w-6 h-6" aria-hidden="true" />
					</span>
					<a
						href={
							deployment?.azureDevops?.projectName
								? `https://dev.azure.com/Bright-HR/${deployment?.azureDevops?.projectName}`
								: '#'
						}
						className="text-lg font-medium focus:outline-none"
					>
						{/* Extend touch target to entire panel */}
						<span className="absolute inset-0" aria-hidden="true" />
						Deployment
					</a>
				</div>
				<span
					className="text-gray-300 pointer-events-none group-hover:text-gray-400"
					aria-hidden="true"
				>
					<svg
						className="block w-6 h-6"
						xmlns="http://www.w3.org/2000/svg"
						fill="currentColor"
						viewBox="0 0 24 24"
					>
						<path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
					</svg>
				</span>
			</div>
			<div className="mt-4">
				{deployment?.azureDevops ? (
					<>
						<p className="mt-2 text-sm text-gray-500">
							Deployment pipelines provided by Azure Devops.
							<br /> Project name: {deployment.azureDevops.projectName}
						</p>
						<dl className="grid grid-cols-2 mt-4 overflow-hidden gap-x-4">
							<div>
								<dt className="text-sm font-normal text-gray-900">
									Days since last deployed
								</dt>
								<dd className="flex items-baseline justify-between mt-1 md:block lg:flex">
									<div className="flex items-baseline text-xl font-semibold text-green-600">
										# days
									</div>
								</dd>
							</div>
							<div>
								<dt className="text-sm font-normal text-gray-900">
									Deployment success rate
								</dt>
								<dd className="flex items-baseline justify-between mt-1 md:block lg:flex">
									<div className="flex items-baseline text-xl font-semibold text-gray-600">
										##.#%
									</div>
								</dd>
							</div>
						</dl>
					</>
				) : (
					<p className="mt-2 text-sm text-gray-500">
						No deployment details specified for this service :(
					</p>
				)}
			</div>
		</div>
		<div
			className={classNames(
				'sm:rounded-tr-lg',
				'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
			)}
		>
			<div className="flex items-center justify-between">
				<div className="flex items-center">
					<span
						className={classNames(
							'text-teal-700',
							'bg-teal-50',
							'rounded-lg inline-flex p-3 ring-4 ring-white mr-4'
						)}
					>
						<EyeIcon className="w-6 h-6" aria-hidden="true" />
					</span>
					<a
						href={monitoring?.appInsights?.url ?? '#'}
						className="text-lg font-medium focus:outline-none"
					>
						{/* Extend touch target to entire panel */}
						<span className="absolute inset-0" aria-hidden="true" />
						Monitoring
					</a>
				</div>
				<span
					className="text-gray-300 pointer-events-none group-hover:text-gray-400"
					aria-hidden="true"
				>
					<svg
						className="block w-6 h-6"
						xmlns="http://www.w3.org/2000/svg"
						fill="currentColor"
						viewBox="0 0 24 24"
					>
						<path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
					</svg>
				</span>
			</div>
			<div className="mt-4">
				{monitoring?.appInsights ? (
					<>
						<p className="mt-2 text-sm text-gray-500">
							Monitoring provided by Azure Application Insights. Last 24hour metrics
							snapshot below.
						</p>
						<dl className="grid grid-cols-3 mt-4 overflow-hidden gap-x-4">
							<div className="">
								<dt className="text-sm font-normal text-gray-900">
									Daily Requests
								</dt>
								<dd className="flex items-baseline justify-between mt-1 md:block lg:flex">
									<div className="flex items-baseline text-xl font-semibold">
										#,###
									</div>
								</dd>
							</div>
							<div className="">
								<dt className="text-sm font-normal text-gray-900">Success Rate</dt>
								<dd className="flex items-baseline justify-between mt-1 md:block lg:flex">
									<div className="flex items-baseline text-xl font-semibold text-red-600">
										##.#%
									</div>
								</dd>
							</div>
							<div className="">
								<dt className="text-sm font-normal text-gray-900">Response Time</dt>
								<dd className="flex items-baseline justify-between mt-1 md:block lg:flex">
									<div className="flex items-baseline text-xl font-semibold text-green-600">
										###ms
									</div>
								</dd>
							</div>
						</dl>
					</>
				) : (
					<p className="mt-2 text-sm text-gray-500">
						No monitoring details provided for this service :(
					</p>
				)}
			</div>
		</div>
		<div
			className={classNames(
				'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
			)}
		>
			<div className="flex items-center justify-between">
				<div className="flex items-center">
					<span
						className={classNames(
							'text-yellow-700',
							'bg-yellow-50',
							'rounded-lg inline-flex p-3 ring-4 ring-white mr-4'
						)}
					>
						<GlobeAltIcon className="w-6 h-6" aria-hidden="true" />
					</span>
					<a href="/" className="text-lg font-medium focus:outline-none">
						{/* Extend touch target to entire panel */}
						<span className="absolute inset-0" aria-hidden="true" />
						Hosting
					</a>
				</div>
				<span
					className="text-gray-300 pointer-events-none group-hover:text-gray-400"
					aria-hidden="true"
				>
					<svg
						className="block w-6 h-6"
						xmlns="http://www.w3.org/2000/svg"
						fill="currentColor"
						viewBox="0 0 24 24"
					>
						<path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
					</svg>
				</span>
			</div>
			<div className="mt-4">
				{hosting ? (
					<>
						<p className="mt-2 text-sm text-gray-500">
							Hosting provided by <strong>{hosting.platform ?? 'unknown'}</strong>.
						</p>
						{hosting.domains?.length > 0 && (
							<>
								<div className="mt-2 text-sm font-normal text-gray-900">
									Domains:
								</div>
								<ul className="mt-1 text-sm text-gray-500">
									{hosting.domains.map((item) => (
										<li>{item}</li>
									))}
								</ul>
							</>
						)}
						{hosting.healthChecks?.length > 0 && (
							<>
								<div className="mt-2 text-sm font-normal text-gray-900">
									Health Checks:
								</div>
								<ul className="mt-1 text-sm text-gray-500">
									{hosting.healthChecks.map((item) => (
										<li>{item.url}</li>
									))}
								</ul>
							</>
						)}
					</>
				) : (
					<p className="mt-2 text-sm text-gray-500">
						No hosting details provided for this service :(
					</p>
				)}
			</div>
		</div>
		<div
			className={classNames(
				'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
			)}
		>
			<div className="flex items-center justify-between">
				<div className="flex items-center">
					<span
						className={classNames(
							'text-purple-700',
							'bg-purple-50',
							'rounded-lg inline-flex p-3 ring-4 ring-white mr-4'
						)}
					>
						<ChatBubbleBottomCenterTextIcon className="w-6 h-6" aria-hidden="true" />
					</span>
					<a
						href={issues?.jira?.boardUrl ?? '#'}
						className="text-lg font-medium focus:outline-none"
					>
						{/* Extend touch target to entire panel */}
						<span className="absolute inset-0" aria-hidden="true" />
						Issue Tracking
					</a>
				</div>
				<span
					className="text-gray-300 pointer-events-none group-hover:text-gray-400"
					aria-hidden="true"
				>
					<svg
						className="block w-6 h-6"
						xmlns="http://www.w3.org/2000/svg"
						fill="currentColor"
						viewBox="0 0 24 24"
					>
						<path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
					</svg>
				</span>
			</div>
			<div className="mt-4">
				{issues?.jira ? (
					<>
						<p className="mt-2 text-sm text-gray-500">
							Issue Tracking provided by Jira.
						</p>
						<p className="mt-2 text-sm text-gray-500">{issues.jira.boardUrl}</p>
					</>
				) : (
					<p className="mt-2 text-sm text-gray-500">
						No issue tracking details provided for this service :(
					</p>
				)}
			</div>
		</div>
	</section>
);
